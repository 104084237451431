// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../utils/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal__DJDMv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  width: 100%;
  max-width: 482px;
  padding: 60px 52px;
  background: #274898;
}
.Modal_modal-container__j-i8d {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99999999999;
}
.Modal_modal-wrapper__2L4ne {
  position: relative;
}
.Modal_modal-close__INoGD {
  display: block;
  cursor: pointer;
  position: absolute;
  top: -80px;
  right: -80px;
  width: 100px;
  height: 100px;
  stroke: #5B79C4;
}
.Modal_modal-close__INoGD:hover {
  stroke: #9AB0E5;
}

@media (max-width: 500px) {
  .Modal_modal__DJDMv {
    width: 100%;
    height: 100%;
  }
  .Modal_modal-close__INoGD {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.css"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,+BAA+B;EAC/B,oBAAoB;AACtB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,eAAe;AACjB;AACA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,cAAc;EAChB;AACF","sourcesContent":["@import '../../utils/fonts.css';\n.modal {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  row-gap: 20px;\n  width: 100%;\n  max-width: 482px;\n  padding: 60px 52px;\n  background: #274898;\n}\n.modal-container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.75);\n  z-index: 99999999999;\n}\n.modal-wrapper {\n  position: relative;\n}\n.modal-close {\n  display: block;\n  cursor: pointer;\n  position: absolute;\n  top: -80px;\n  right: -80px;\n  width: 100px;\n  height: 100px;\n  stroke: #5B79C4;\n}\n.modal-close:hover {\n  stroke: #9AB0E5;\n}\n\n@media (max-width: 500px) {\n  .modal {\n    width: 100%;\n    height: 100%;\n  }\n  .modal-close {\n    display: block;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Modal_modal__DJDMv`,
	"modal-container": `Modal_modal-container__j-i8d`,
	"modal-wrapper": `Modal_modal-wrapper__2L4ne`,
	"modal-close": `Modal_modal-close__INoGD`
};
export default ___CSS_LOADER_EXPORT___;
