import React from 'react'
import classes from './Modal.module.css'
import Icons from '../Icons/Icons'

const Modal = ({children, setModalIsActive}) => {

  return (
    <div className={`${classes['modal-container']}`} onClick={() => setModalIsActive(false)}>
      <div className={`${classes['modal-wrapper']}`}>
        <Icons
          name='x'
          className={`${classes['modal-close']}`}
          onClick={() => setModalIsActive(false)}
        />
        <div
          className={`${classes['modal']}`}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal