import React, { useEffect, useRef, useState } from 'react'
import classes from './Settings.module.css'
import NavBar from '../../components/NavBar/NavBar'
import Wrapper from '../../components/Wrapper/Wrapper'
import H1 from '../../components/H1/H1'
import { Button } from '../../components/Button/Button'
import CollapseComponent from '../../components/Collapse/CollapseComponent'
import { DatePicker, Input, Select, TimePicker, Upload } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { changeSetting, getSettingsList } from '../../http/admin'
import { getStops } from '../../http/trip'
import { setFromStopList, setSelectedDate, setToStopList } from '../../store/TripStore'
import moment from 'moment'
import dayjs from 'dayjs'
import TextArea from 'antd/es/input/TextArea'

const Settings = () => {
  const [settingsList, setSettingsList] = useState([])
  const [settingOrderCreate, setSettingOrderCreate] = useState({})
  const [selectedDates, setSelectedDates] = useState([])
  const fromStopList = useSelector(state => state.trip._fromStopList)
  const toStopList = useSelector(state => state.trip._toStopList)
  const [selectedToStop, setSelectedToStop] = useState(null)
  const [selectedFromStop, setSelectedFromStop] = useState(null)
  const [settingInformationMessageText, setSettingInformationMessageText] = useState({})
  const [newInformationMessageText, setNewInformationMessageText] = useState(settingInformationMessageText.value)
  const [orderCreateIsChanging, setOrderCreateIsChanging] = useState(true)
  const [informationMessageIsChanging, setInformationMessageIsChanging] = useState(false)
  const datapicker = useRef()
  const dispatch = useDispatch()
  let menu = [
    {
      id: '14222424',
      name: 'Название 1',
      description: 'Описание 1',
      price: 500,
      image: 'image1',
    },
    {
      id: '14222425',
      name: 'Название 2',
      description: 'Описание 2',
      price: 500,
      image: 'image2',
    },
    {
      id: '14222426',
      name: 'Название 3',
      description: 'Описание 3',
      price: 500,
      image: 'image3',
    },
  ]
  const [menuIsEditing, setMenuIsEditing] = useState(menu.map((item) => Object.assign([item.id, false])))

  const filterEditMenu = (id, index) => {
    let temp = menuIsEditing
    temp[index][1] = !temp[index][1]
    setMenuIsEditing([...temp])
  }

  const changeSelectedStopValue = (selectedTypeValue, setSelectedTypeValue, value) => {
    if (selectedTypeValue === value) {
      alert(`Нельзя выбрать один и тот же город как пункт отправления и как пункт прибытия`)
    }
    else {
      setSelectedTypeValue(value)
    }
  }

  const changeSelectedDates = (dates) => {
    setSelectedDates(dates)
    console.log(selectedDates)
  }

  const fetchStops = async () => {
    try {
      let {data} = await getStops()
      console.log(data)
      dispatch(setFromStopList(data))
      dispatch(setToStopList(data))
    } catch (e) {
      console.log(e)
    }
  }

  const fetchSettingsList = async () => {
    setOrderCreateIsChanging(true)
    try {
      let {data} = await getSettingsList(10, 0)
      console.log(data)
      setSettingsList(data.items)
      setSettingOrderCreate(data.items.find((item) => item['name'].includes('order.create.enabled')))
      setSettingInformationMessageText(data.items.find((item) => item['name'].includes('order.create.disabled_message')))
      setNewInformationMessageText(data.items.find((item) => item['name'].includes('order.create.disabled_message')).value)
    } catch (e) {
      console.log(e)
    } finally {
      setOrderCreateIsChanging(false)
    }
  }

  const fetchChangeSetting = async (id, value, setButtonLoading) => {
    console.log(settingsList)
    console.log(id, value)
    setButtonLoading(true)
    try {
      let {data} = await changeSetting(id, value)
      console.log(data)
      setButtonLoading(false)
    } catch (e) {
      console.log(e)
    } finally {
      setButtonLoading(false)
      fetchSettingsList()
    }
  }

  useEffect(() => {
    fetchSettingsList()
    if (fromStopList.length === 0 || toStopList.length === 0) {
      fetchStops()
    }
  }, [])

  return (
    <>
      <NavBar/>
      <Wrapper>
        <H1>Настройки</H1>
        <div className={`${classes['settings-wrapper']}`}>
          <Button
            onClick={() => fetchChangeSetting(settingOrderCreate.id, settingOrderCreate.value === 'true' ? 'false' : 'true', setOrderCreateIsChanging)}
            loading={orderCreateIsChanging}
          >
            {settingOrderCreate && settingOrderCreate.value === 'true' ? 'Отключить продажи' : 'Включить продажи'}
          </Button>
          <div className={`${classes['settings']}`}>
            <div className={classes['trip-block-container']}>
              <CollapseComponent
                className={`${classes['trip-collapse']} ${classes['trip-blockSales-collapse']}`}
                iconContainerClassName={classes['trip-collapse-iconContainer']}
                hasPseudos={false}
                text={
                  <div className={`${classes['trip-blockSales']} ${classes['trip-block']}`}>
                    <div>
                      <DatePicker
                        ref={datapicker}
                        className='trip-blockSales-datePick'
                        popupClassName='trip-blockSales-datePick-popup'
                        maxDate={moment('2030-01-01')}
                        minDate={moment().add(-1, "days")}
                        defaultOpenValue='true'
                        value={selectedDates}
                        hidden={false}
                        multiple
                        placement='bottomLeft'
                        placeholder='Выберите дату'
                        onCalendarChange={(value) => {
                          changeSelectedDates(value)
                        }}
                      />
                    </div>
                    <Button
                      className={classes['trip-blockSales-buttonConfirm']}
                    >
                      Заблокировать
                    </Button>
                  </div>
                }
                label='Заблокировать продажи'
                // isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
            <div className={classes['trip-block-container']}>
              <CollapseComponent
                className={`${classes['trip-collapse']}`}
                iconContainerClassName={classes['trip-collapse-iconContainer']}
                hasPseudos={false}
                text={
                  <div className={`${classes['trip-block']} ${classes['trip-messageInfo']}`}>
                    <div className={`${classes['trip-block']}`}>
                      <TextArea
                        className={classes['trip-messageInfo-textArea']}
                        placeholder={'Текущее информационное сообщение'}
                        value={settingInformationMessageText.value}
                        autoSize
                        disabled
                      />
                      {settingOrderCreate.value === 'true' ? 'Сейчас неактивно' : 'Сейчас активно'}
                    </div>
                    <div className={`${classes['trip-block']}`}>
                      <TextArea
                        className={classes['trip-messageInfo-textArea']}
                        placeholder={'Новое информационное сообщение'}
                        defaultValue={settingInformationMessageText.value}
                        autoSize
                        onChange={(e) => setNewInformationMessageText(e.target.value)}
                      />
                      <div className={`${classes['trip-messageInfo-settings']}`}>
                        <div className={`${classes['trip-messageInfo-settings-actions']}`}>
                          <Button
                            className={classes['trip-messageInfo-buttonConfirm']}
                            onClick={() => fetchChangeSetting(settingInformationMessageText.id, newInformationMessageText, setInformationMessageIsChanging)}
                            loading={informationMessageIsChanging}
                          >
                            Изменить
                          </Button>
                          {/* <Button
                            className={classes['trip-messageInfo-buttonConfirm']}
                            onClick={() => setInformationMessageIsActive((informationMessageIsActive) => !informationMessageIsActive)}
                          >
                            {settingOrderCreate.value === 'true' ? 'Отключить' : 'Включить'}
                          </Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                }
                label='Добавить/изменить информационное сообщение'
                // isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
            <div className={classes['trip-block-container']}>
              <CollapseComponent
                className={`${classes['trip-collapse']}`}
                iconContainerClassName={classes['trip-collapse-iconContainer']}
                hasPseudos={false}
                text={
                  <div className={`${classes['trip-blockStops']} ${classes['trip-block']}`}>
                    <div className={`${classes['trip-blockStops-stops']}`}>
                      {fromStopList.map((stop) => 
                        <div className={`${classes['trip-blockStops-stops-stop']}`} key={stop.id}>
                          <div>{stop.name}</div>
                          <div>Активно</div>
                        </div>
                      )}
                    </div>
                    <div className={`${classes['trip-blockStops-settings']}`}>
                      <Select
                        className={`select ${classes['trip-blockStops-select']} ${'passengerInfo-select'}`}
                        placeholder='Пункт для блокировки'
                      >
                        {fromStopList.map(stop => 
                          <Select.Option value={stop.id} key={stop.id} label={stop.name}>{stop.name}</Select.Option>
                        )}
                      </Select>
                      <Button
                        className={classes['trip-blockStops-buttonConfirm']}
                      >
                        Заблокировать
                      </Button>
                      <Button
                        className={classes['trip-blockStops-buttonConfirm']}
                      >
                        Отмена
                      </Button>
                    </div>
                  </div>
                }
                label='Заблокировать направление'
                // isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
            <div className={classes['trip-block-container']}>
              <CollapseComponent
                className={`${classes['trip-collapse']}`}
                iconContainerClassName={classes['trip-collapse-iconContainer']}
                hasPseudos={false}
                text={
                  <div className={`${classes['trip-changePrices']} ${classes['trip-block']}`}>
                    <div className={`${classes['trip-changePrices-tables']}`}>
                      <div className={`${classes['trip-changePrices-table-title']}`}>Текущие цены</div>
                      <div className={`${classes['trip-changePrices-table']}`}>
                        <p className={`${classes['trip-changePrices-description']}`}></p>
                        <p className={`${classes['trip-changePrices-description']}`}>Базовый</p>
                        <p className={`${classes['trip-changePrices-description']}`}>Комфорт</p>
                        <p className={`${classes['trip-changePrices-description']}`}>Взрослый</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                        <p className={`${classes['trip-changePrices-description']}`}>Детский</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                        <p className={`${classes['trip-changePrices-description']}`}>Багаж</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                      </div>
                      <div className={`${classes['trip-changePrices-table-title']}`}>Новые цены</div>
                      <div className={`${classes['trip-changePrices-table']}`}>
                        <p className={`${classes['trip-changePrices-description']}`}></p>
                        <p className={`${classes['trip-changePrices-description']}`}>Базовый</p>
                        <p className={`${classes['trip-changePrices-description']}`}>Комфорт</p>
                        <p className={`${classes['trip-changePrices-description']}`}>Взрослый</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                        <p className={`${classes['trip-changePrices-description']}`}>Детский</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                        <p className={`${classes['trip-changePrices-description']}`}>Багаж</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                      </div>
                    </div>
                    <Button
                      className={classes['trip-changePrices-buttonConfirm']}
                    >
                      Подтвердить
                    </Button>
                    <Button
                      className={classes['trip-changePrices-buttonConfirm']}
                    >
                      Отмена
                    </Button>
                  </div>
                }
                label='Изменить стоимость билетов на все рейсы'
                // isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
            <div className={classes['trip-block-container']}>
              <CollapseComponent
                className={`${classes['trip-collapse']}`}
                iconContainerClassName={classes['trip-collapse-iconContainer']}
                hasPseudos={false}
                text={
                  <div className={`${classes['trip-changeDiscounts']} ${classes['trip-block']}`}>
                    <div className={`${classes['trip-changePrices-tables']}`}>
                      <div className={`${classes['trip-changePrices-table-title']}`}>Текущие скидки</div>
                      <div className={`${classes['trip-changePrices-table']}`}>
                        <p className={`${classes['trip-changePrices-description']}`}></p>
                        <p className={`${classes['trip-changePrices-description']}`}>Базовый</p>
                        <p className={`${classes['trip-changePrices-description']}`}>Комфорт</p>
                        <p className={`${classes['trip-changePrices-description']}`}>Взрослый</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                        <p className={`${classes['trip-changePrices-description']}`}>Детский</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                        <p className={`${classes['trip-changePrices-description']}`}>Багаж</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                        <Input className={`input ${classes['trip-changePrices-input']}`} disabled/>
                      </div>
                      <div className={`${classes['trip-changePrices-table-title']}`}>Новые скидки</div>
                      <div className={`${classes['trip-changePrices-table']}`}>
                        <p className={`${classes['trip-changePrices-description']}`}></p>
                        <p className={`${classes['trip-changePrices-description']}`}>Базовый</p>
                        <p className={`${classes['trip-changePrices-description']}`}>Комфорт</p>
                        <p className={`${classes['trip-changePrices-description']}`}>Взрослый</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                        <p className={`${classes['trip-changePrices-description']}`}>Детский</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                        <p className={`${classes['trip-changePrices-description']}`}>Багаж</p>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                        <Input className={`input ${classes['trip-changePrices-input']}`}/>
                      </div>
                    </div>
                    <Button
                      className={classes['trip-changeDiscounts-buttonConfirm']}
                    >
                      Подтвердить
                    </Button>
                    <Button
                      className={classes['trip-changeDiscounts-buttonConfirm']}
                    >
                      Отмена
                    </Button>
                  </div>
                }
                label='Сделать скидку на билеты на все рейсы'
                // isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
            <div className={classes['trip-block-container']}>
              <CollapseComponent
                className={`${classes['trip-collapse']}`}
                iconContainerClassName={classes['trip-collapse-iconContainer']}
                hasPseudos={false}
                text={
                  <div className={`${classes['trip-changeArrivalDepartureTime']} ${classes['trip-block']}`}>
                    <div className={`${classes['trip-changeArrivalDepartureTime-stops']}`}>
                      <Select
                        className={`select ${classes['trip-blockStops-select']} ${'passengerInfo-select'}`}
                        placeholder='Пункт отправления'
                        showSearch
                        defaultValue={selectedFromStop}
                        value={selectedFromStop}
                        label="true"
                        optionFilterProp='label'
                        onChange={function(value) {
                          changeSelectedStopValue(selectedToStop, setSelectedFromStop, value)
                        }}
                      >
                        {fromStopList.map(stop => 
                          <Select.Option value={stop.id} key={stop.id} label={stop.name}>{stop.name}</Select.Option>
                        )}
                      </Select>
                      <Select
                        className={`select ${classes['trip-blockStops-select']} ${'passengerInfo-select'}`}
                        placeholder='Пункт прибытия'
                        showSearch
                        defaultValue={selectedToStop}
                        value={selectedToStop}
                        label="true"
                        optionFilterProp='label'
                        onChange={function(value) {
                          changeSelectedStopValue(selectedFromStop, setSelectedToStop, value)
                        }}
                      >
                        {toStopList.map(stop => 
                          <Select.Option value={stop.id} key={stop.id} label={stop.name}>{stop.name}</Select.Option>
                        )}
                      </Select>
                    </div>
                    <div className={classes['trip-changeArrivalDepartureTime-departure']}>
                      <TimePicker
                        className={`timePicker ${classes['trip-changeArrivalDepartureTime-timePicker']} ${classes['trip-changeArrivalDepartureTime-timePicker__departure']}`}
                        placeholder={'Время отправления'}
                        format={'HH:mm'}
                        hourStep={'1'}
                        minuteStep={'5'}
                      />
                      <Button
                        className={classes['trip-changeArrivalDepartureTime-buttonConfirm']}
                      >
                        Подтвердить
                      </Button>
                      <Button
                        className={classes['trip-changeArrivalDepartureTime-buttonConfirm']}
                      >
                        Отмена
                      </Button>
                    </div>
                    <div className={classes['trip-changeArrivalDepartureTime-arrival']}>
                      <TimePicker
                        className={`timePicker ${classes['trip-changeArrivalDepartureTime-timePicker']} ${classes['trip-changeArrivalDepartureTime-timePicker__arrival']}`}
                        placeholder={'Время прибытия'}
                        format={'HH:mm'}
                        hourStep={'1'}
                        minuteStep={'5'}
                      />
                      <Button
                        className={classes['trip-changeArrivalDepartureTime-buttonConfirm']}
                      >
                        Подтвердить
                      </Button>
                      <Button
                        className={classes['trip-changeArrivalDepartureTime-buttonConfirm']}
                      >
                        Отмена
                      </Button>
                    </div>
                  </div>
                }
                label='Изменить время отправления/прибытия'
                // isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
            <div className={classes['trip-block-container']}>
              <CollapseComponent
                className={`${classes['trip-collapse']}`}
                iconContainerClassName={classes['trip-collapse-iconContainer']}
                hasPseudos={false}
                text={
                  <div className={`${classes['trip-editMenu']} ${classes['trip-block']}`}>
                    <div className={classes['trip-editMenu-controls']}>
                      <Button
                        className={classes['trip-editMenu-buttonConfirm']}
                      >
                        Отключить продажи питания
                      </Button>
                      <Button
                        className={classes['trip-editMenu-buttonConfirm']}
                      >
                        Вернуть деньги за питание
                      </Button>
                    </div>
                    <div className={`${classes['trip-editMenu-table']}`}>
                      <div className={`${classes['trip-editMenu-table-header']}`}>
                        <div className={`${classes['trip-editMenu-table-data-item-title']}`}>Название</div>
                        <div className={`${classes['trip-editMenu-table-data-item-title']}`}>Описание</div>
                        <div className={`${classes['trip-editMenu-table-data-item-title']}`}>Цена</div>
                        <div className={`${classes['trip-editMenu-table-data-item-title']}`}>Изображение</div>
                      </div>
                      {menu.map((item, index) => 
                        !menuIsEditing.find((elem, index) => elem[0] === item.id)[1]
                        ?
                          <div className={`${classes['trip-editMenu-table-data']}`} key={item.name}>
                            <div className={`${classes['trip-editMenu-table-data-item']}`}>
                              <div className={`${classes['trip-editMenu-table-data-item-content']}`}>{item.name}</div>
                            </div>
                            <div className={`${classes['trip-editMenu-table-data-item']}`}>
                              <div className={`${classes['trip-editMenu-table-data-item-content']}`}>{item.description}</div>
                            </div>
                            <div className={`${classes['trip-editMenu-table-data-item']}`}>
                              <div className={`${classes['trip-editMenu-table-data-item-content']}`}>{item.price}</div>
                            </div>
                            <div className={`${classes['trip-editMenu-table-data-item']}`}>
                              <div className={`${classes['trip-editMenu-table-data-item-content']}`}>{item.image}</div>
                            </div>
                            <Button
                              className={classes['trip-editMenu-buttonConfirm']}
                              onClick={() => filterEditMenu(item.id, index)}
                            >
                              Изменить
                            </Button>
                          </div>
                        :
                          <div className={`${classes['trip-editMenu-table-data']}`} key={item.name}>
                            <Input
                              className={`input ${classes['trip-editMenu-table-data-controls-input']}`}
                              placeholder='Название'
                            />
                            <TextArea
                              className={`input ${classes['trip-editMenu-table-data-controls-input']}`}
                              placeholder='Описание'
                              rows={1}
                              autoSize
                            />
                           <Input
                              className={`input ${classes['trip-editMenu-table-data-controls-input']}`}
                              placeholder='Цена'
                            />
                            <Upload
                            >
                              <Button>
                                Загрузить
                              </Button>
                            </Upload>
                            <Button
                              className={classes['trip-editMenu-buttonConfirm']}
                            >
                              Подтвердить
                            </Button>
                            <Button
                              className={classes['trip-editMenu-buttonConfirm']}
                              onClick={() => filterEditMenu(item.id, index)}
                            >
                              Отмена
                            </Button>
                          </div>
                      )}
                    </div>
                  </div>
                }
                label='Редактировать меню'
                // isWindowWidthTablet={isWindowWidthTablet}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Settings