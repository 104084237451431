import React, { useEffect, useState } from 'react'
import classes from './Users.module.css'
import NavBar from '../../components/NavBar/NavBar'
import Wrapper from '../../components/Wrapper/Wrapper'
import H1 from '../../components/H1/H1'
import { Button } from '../../components/Button/Button'
import { getDefaultSettings, getUsersList } from '../../http/admin'
import dayjs from 'dayjs'
import { Pagination, Select } from 'antd'
import { getStorageWithExpiry } from '../../utils/getStorageWithExpiry'
import { USERS_ROUTE } from '../../utils/consts'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'

const Users = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [usersListLimit, setUsersListLimit] = useState(params.get('limit') || 50)
  const [usersListOffset, setUsersListOffset] = useState(params.get('offset') || 0)
  const [usersListPage, setUsersListPage] = useState(params.get('page') || 1)
  const [usersListTotalCount, setUsersListTotalCount] = useState(0)
  const [usersListOrderBy, setUsersListOrderBy] = useState(params.get('orderBy') || 'createdAt')
  const [usersListOrderByDirection, setUsersListOrderByDirection] = useState(params.get('orderByDirection') || 'desc')
  const [usersListLastName, setUsersListLastName] = useState(params.get('lastName') !== 'null' ? params.get('lastName') : null)
  const [usersListEmail, setUsersListEmail] = useState(params.get('email') !== 'null' ? params.get('email') : null)
  const [usersListPhoneNumber, setUsersListPhoneNumber] = useState(params.get('phoneNumber') !== 'null' ? params.get('phoneNumber') : null)
  const [usersList, setUsersList] = useState([])
  const [usersListIsLoading, setUsersListIsLoading] = useState(false)

  const fetchUsersList = async (limit = null, offset = null, orderBy = null, orderByDirection = null, lastName = null, email = null, phoneNumber = null) => {
    setUsersListIsLoading(true)
    try {
      let {data} = await getUsersList(
        limit === null ? usersListLimit : limit,
        offset === null ? usersListOffset : offset,
        orderBy ? orderBy : usersListOrderBy,
        orderByDirection ? orderByDirection : usersListOrderByDirection,
        lastName ? lastName : usersListLastName,
        email ? email : usersListEmail,
        phoneNumber ? phoneNumber : usersListPhoneNumber,
      )
      console.log('items: ', data.items)
      console.log('total: ', data.total)
      setUsersList(data.items)
      if (data.total) {
        setUsersListTotalCount(data.total)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setUsersListIsLoading(false)
    }
  }

  const changeUsersListPage = (page, pageSize) => {
    setUsersListOffset(page * pageSize - pageSize)
    fetchUsersList(pageSize, page * pageSize - pageSize, usersListOrderBy, usersListOrderByDirection, usersListLastName, usersListEmail, usersListPhoneNumber)
    changeParams(page, pageSize, page * pageSize - pageSize, usersListOrderBy, usersListOrderByDirection, usersListLastName, usersListEmail, usersListPhoneNumber)
  }

  const changeUsersListLimit = (limit) => {
    let currentItem = usersListPage * usersListLimit
    setUsersListLimit(limit)
    let page = usersListPage
    if (currentItem > usersListTotalCount) {
      page = Math.ceil(usersListTotalCount/limit)
    }
    else {
      page = Math.floor(usersListOffset/limit + 1)
    }
    setUsersListPage(page)
    setUsersListOffset(page * limit - limit)
    changeParams(page, limit, page * limit - limit, usersListOrderBy, usersListOrderByDirection, usersListLastName, usersListEmail, usersListPhoneNumber)
    if (usersList.length > 0) {
      fetchUsersList(limit, page * limit - limit, usersListOrderBy, usersListOrderByDirection, usersListLastName, usersListEmail, usersListPhoneNumber)
    }
  }

  const changeUsersListOrderBy = (value) => {
    setUsersListOrderBy(value)
    changeParams(usersListPage, usersListLimit, usersListOffset, usersListOrderBy, value, usersListLastName, usersListEmail, usersListPhoneNumber)
    if (usersList.length > 0) {
      fetchUsersList(usersListLimit, usersListOffset, value, usersListOrderByDirection, usersListLastName, usersListEmail, usersListPhoneNumber)
    }
  }

  const changeUsersListOrderByDirection = (value) => {
    setUsersListOrderByDirection(value)
    changeParams(usersListPage, usersListLimit, usersListOffset, usersListOrderBy, value, usersListLastName, usersListEmail, usersListPhoneNumber)
    if (usersList.length > 0) {
      fetchUsersList(usersListLimit, usersListOffset, usersListOrderBy, value, usersListLastName, usersListEmail, usersListPhoneNumber)
    }
  }

  const changeParams = (page, limit, offset, orderBy, orderByDirection, lastName, email, phoneNumber) => {
    navigate({
      pathname: USERS_ROUTE,
      search: `?${createSearchParams({
        page: page,
        limit: limit,
        offset: offset,
        orderBy: orderBy,
        orderByDirection: orderByDirection,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
      })}`
    })
  }

  useEffect(() => {
    fetchUsersList()
    if (!params.get('page')) {
      changeParams(1, 50, 0, 'createdAt', 'desc', null, null, null)
    }
  }, [getStorageWithExpiry('accessToken')])
  
  return (
    <>
      <NavBar/>
      <Wrapper>
        <H1>Пользователи</H1>
        <div className={classes['usersList']}>
          <div className={classes['usersList-search']}>
            {!(usersListTotalCount < usersListLimit) &&
              <Pagination
                hideOnSinglePage
                rootClassName={'pagination'}
                disabled={usersListTotalCount < usersListLimit}
                current={usersListPage}
                defaultCurrent={usersListPage}
                defaultPageSize={usersListLimit}
                pageSize={usersListLimit}
                total={usersListTotalCount}
                showSizeChanger={false}
                onChange={(page, pageSize) => {
                  setUsersListPage(page)
                  changeUsersListPage(page, pageSize)
                }}
              />
            }
            <div className={classes['usersList-search-column']}>              
              <Select
                loading={usersListIsLoading}
                disabled={usersListIsLoading}
                className={`select ${classes['usersList-limit-select']}`}
                value={usersListOrderBy}
                style={{width: '300px'}}
                placeholder='Сортировать по полю'
                label="true"
                optionFilterProp='label'
                onChange={(value) => changeUsersListOrderBy(value)}
              >
                <Select.Option value='email'>email</Select.Option>
                <Select.Option value='phoneNumber'>Номер телефона</Select.Option>
                <Select.Option value='createdAt'>Дата создания</Select.Option>
              </Select>
              <Select
                loading={usersListIsLoading}
                disabled={usersListIsLoading}
                className={`select ${classes['usersList-limit-select']}`}
                value={usersListOrderByDirection}
                style={{width: '300px'}}
                placeholder='Сортировать по направлению'
                label="true"
                optionFilterProp='label'
                onChange={(value) => changeUsersListOrderByDirection(value)}
              >
                <Select.Option value='asc'>По возрастанию</Select.Option>
                <Select.Option value='desc'>По убыванию</Select.Option>
              </Select>
              <Select
                loading={usersListIsLoading}
                disabled={usersListIsLoading}
                className={`select ${classes['usersList-limit-select']}`}
                value={usersListLimit}
                style={{width: '100px'}}
                placeholder='Количество записей'
                label="true"
                optionFilterProp='label'
                onChange={function(value) {
                  changeUsersListLimit(value)
                }}
              >
                <Select.Option value={10} key={10} label='10'>10</Select.Option>
                <Select.Option value={25} key={25} label='25'>25</Select.Option>
                <Select.Option value={50} key={50} label='50'>50</Select.Option>
                <Select.Option value={100} key={100} label='100'>100</Select.Option>
              </Select>
            </div>
          </div>
          <div className={classes['usersList-list-wrapper']}>
            <div className={classes['usersList-list']}>
              <div className={classes['usersList-list-header']}>
                <div className={`${classes['usersList-list-header-item']}`}>
                  <div className={`${classes['usersList-list-header-item-title']}`}>email</div>
                </div>
                <div className={`${classes['usersList-list-header-item']}`}>
                  <div className={`${classes['usersList-list-header-item-title']}`}>ФИО</div>
                </div>
                <div className={`${classes['usersList-list-header-item']}`}>
                  <div className={`${classes['usersList-list-header-item-title']}`}>Номер телефона</div>
                </div>
                <div className={`${classes['usersList-list-header-item']}`}>
                  <div className={`${classes['usersList-list-header-item-title']}`}>Роли</div>
                </div>
                <div className={`${classes['usersList-list-header-item']}`}>
                  <div className={`${classes['usersList-list-header-item-title']}`}>Статус</div>
                </div>
                <div className={`${classes['usersList-list-header-item']}`}>
                  <div className={`${classes['usersList-list-header-item-title']}`}>Создан</div>
                </div>
                <div className={`${classes['usersList-list-header-item']}`}>
                  <div className={`${classes['usersList-list-header-item-title']}`}>Обновлён</div>
                </div>
                <div className={`${classes['usersList-list-header-item']}`}>
                  <div className={`${classes['usersList-list-header-item-title']}`}>Действия</div>
                </div>
              </div>
              {usersList.map((user) => 
                <div className={classes['usersList-list-user']} key={user.id}>
                  <div className={classes['usersList-list-user-data']}>
                    <div className={`${classes['usersList-list-user-data-item']}`}>
                      <div className={`${classes['usersList-list-user-data-item-value']} ${classes['usersList-list-user-data-item-value__email']}`}>{user.email}</div>
                    </div>
                    <div className={`${classes['usersList-list-user-data-item']}`}>
                      <div className={`${classes['usersList-list-user-data-item-value']}`}>{user.lastName} {user.firstName} {user.middleName}</div>
                    </div>
                    <div className={`${classes['usersList-list-user-data-item']}`}>
                      <div className={`${classes['usersList-list-user-data-item-value']}`}>{user.phoneNumber}</div>
                    </div>
                    <div className={`${classes['usersList-list-user-data-item']}`}>
                      <div className={`${classes['usersList-list-user-data-item-value']}}`}>
                        {user.roles.map((role) => 
                          <span className={`${classes['usersList-list-user-data-item-value']} ${classes['usersList-list-user-data-item-value__role']}`} key={role}>{role}</span>
                        )}
                      </div>
                    </div>
                    <div className={`${classes['usersList-list-user-data-item']} ${classes['usersList-list-user-data-item__status']}`}>
                      <div className={`${classes['usersList-list-user-data-item-value']}`}>{user.status}</div>
                    </div>
                    <div className={`${classes['usersList-list-user-data-item']}`}>
                      <div className={`${classes['usersList-list-user-data-item-value']}`}>{dayjs(user.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                    </div>
                    <div className={`${classes['usersList-list-user-data-item']}`}>
                      <div className={`${classes['usersList-list-user-data-item-value']}`}>{dayjs(user.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                    </div>
                    <div className={`${classes['usersList-list-user-data-item']}`}> 
                      <div className={`${classes['usersList-list-user-data-item-value']} ${classes['usersList-list-user-data-item-value__actions']}`}>
                        <span>
                          <Button className={`${classes['usersList-list-user-data-editButton']}`}>Редактировать</Button>
                        </span>
                        <span>
                          <Button className={`${classes['usersList-list-user-data-editButton']}`}>{user.status === 'banned' ? 'Включить' : 'Отключить'}</Button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {!(usersListTotalCount < usersListLimit) &&
              <Pagination
                hideOnSinglePage
                rootClassName={'pagination'}
                disabled={usersListTotalCount < usersListLimit}
                current={usersListPage}
                defaultCurrent={usersListPage}
                defaultPageSize={usersListLimit}
                pageSize={usersListLimit}
                total={usersListTotalCount}
                showSizeChanger={false}
                onChange={(page, pageSize) => {
                  setUsersListPage(page)
                  changeUsersListPage(page, pageSize)
                }}
              />
            }
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Users