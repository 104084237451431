// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../utils/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingSpin_spinBox__MnrGn {
  display: flex;
}
.LoadingSpin_spinBox_fullpage__xLd6E {
  grid-auto-flow: column;
  position: relative;
  justify-content: space-between;
  align-items: center;
  max-height: 100vh;
}
.LoadingSpin_spinBox_component__4qFri {
  height: auto;
  width: auto;
  justify-content: center;
  align-items: center;
}
.LoadingSpin_spinBox-separator__SYpj0 {
  height: 1px;
  width: 41%;
  background: #5B79C4;
}
.LoadingSpin_spin-container__dIhvO {
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoadingSpin_spin-container__dIhvO .LoadingSpin_logo__7Nc3r {
  position: absolute;
}

.LoadingSpin_spin__2f1Z- {
  display: block;
  background: transparent;
  animation: infinite 4s LoadingSpin_rotates__sAU\\+T linear;
}
.LoadingSpin_spin_fullpage__EXV40 {
  border-color: #274898;
}
.LoadingSpin_spin_component__uSQYT {
  border-color: #274898;
}

@keyframes LoadingSpin_rotates__sAU\\+T {
  0% {
    rotate: 0deg;
  }
  50% {
    rotate: 180deg;
  }
  100% {
    rotate: 360deg;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpin/LoadingSpin.module.css"],"names":[],"mappings":"AACA;EACE,aAAa;AACf;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,UAAU;EACV,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,uBAAuB;EACvB,yDAAqC;AACvC;AACA;EACE,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,YAAY;EACd;EACA;IACE,cAAc;EAChB;EACA;IACE,cAAc;EAChB;AACF","sourcesContent":["@import '../../utils/fonts.css';\n.spinBox {\n  display: flex;\n}\n.spinBox_fullpage {\n  grid-auto-flow: column;\n  position: relative;\n  justify-content: space-between;\n  align-items: center;\n  max-height: 100vh;\n}\n.spinBox_component {\n  height: auto;\n  width: auto;\n  justify-content: center;\n  align-items: center;\n}\n.spinBox-separator {\n  height: 1px;\n  width: 41%;\n  background: #5B79C4;\n}\n.spin-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.spin-container .logo {\n  position: absolute;\n}\n\n.spin {\n  display: block;\n  background: transparent;\n  animation: infinite 4s rotates linear;\n}\n.spin_fullpage {\n  border-color: #274898;\n}\n.spin_component {\n  border-color: #274898;\n}\n\n@keyframes rotates {\n  0% {\n    rotate: 0deg;\n  }\n  50% {\n    rotate: 180deg;\n  }\n  100% {\n    rotate: 360deg;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinBox": `LoadingSpin_spinBox__MnrGn`,
	"spinBox_fullpage": `LoadingSpin_spinBox_fullpage__xLd6E`,
	"spinBox_component": `LoadingSpin_spinBox_component__4qFri`,
	"spinBox-separator": `LoadingSpin_spinBox-separator__SYpj0`,
	"spin-container": `LoadingSpin_spin-container__dIhvO`,
	"logo": `LoadingSpin_logo__7Nc3r`,
	"spin": `LoadingSpin_spin__2f1Z-`,
	"rotates": `LoadingSpin_rotates__sAU+T`,
	"spin_fullpage": `LoadingSpin_spin_fullpage__EXV40`,
	"spin_component": `LoadingSpin_spin_component__uSQYT`
};
export default ___CSS_LOADER_EXPORT___;
