import React, { useEffect, useState } from 'react'
import classes from './Trips.module.css'
import NavBar from '../../components/NavBar/NavBar'
import Wrapper from '../../components/Wrapper/Wrapper'
import H1 from '../../components/H1/H1'
import { notification, Pagination, Select } from 'antd'
import dayjs from 'dayjs'
import { enableTrip, getTripsList } from '../../http/admin'
import { Button } from '../../components/Button/Button'
import { createSearchParams, NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { beige4, TRIP_ROUTE, TRIPS_ROUTE } from '../../utils/consts'
import { useDispatch, useSelector } from 'react-redux'
import { setTrip } from '../../store/TripStore'
import { getStorageWithExpiry } from '../../utils/getStorageWithExpiry'
import LoadingSpin from '../../components/LoadingSpin/LoadingSpin'
import Modal from '../../components/Modal/Modal'
import Icons from '../../components/Icons/Icons'

const Trips = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [tripsListLimit, setTripsListLimit] = useState(params.get('limit') || 50)
  const [tripsListOffset, setTripsListOffset] = useState(params.get('offset') || 0)
  const [tripsListPage, setTripsListPage] = useState(params.get('page') || 1)
  const [tripsListTotalCount, setTripsListTotalCount] = useState(0)
  const [tripsListOrderBy, setTripsListOrderBy] = useState(params.get('orderBy') || 'dateTime')
  const [tripsListOrderByDirection, setTripsListOrderByDirection] = useState(params.get('orderByDirection') || 'desc')
  const [tripsList, setTripsList] = useState([])
  const [tripsListIsLoading, setTripsListIsLoading] = useState(false)
  const [modalEnableTripIsActive, setModalEnableTripIsActive] = useState(false)
  const [enableTripData, setEnableTripData] = useState(null)
  const allDictionaries = useSelector(state => state.dictionary._dictionaries)
  const tripStatuses = allDictionaries.tripStatuses
  const dispatch = useDispatch()

  const [messageApi, contextHolder] = notification.useNotification()
  const returnMessage = (type, title, content, className, style, duration) => {
    messageApi.open({
      // type: type,
      message: title,
      description: content,
      className: 'notification',
      style: style,
      duration: duration,
      placement: 'top',
      closeIcon:
        <Icons
          name='x'
          size='24px'
          stroke={beige4}
        />,
    })
  }

  const fetchTripsList = async (limit = null, offset = null, orderBy = null, orderByDirection = null) => {
    setTripsListIsLoading(true)
    try {
      let {data} = await getTripsList(limit === null ? tripsListLimit : limit, offset === null ? tripsListOffset : offset, orderBy ? orderBy : tripsListOrderBy, orderByDirection ? orderByDirection : tripsListOrderByDirection)
      console.log('items: ', data.items)
      console.log('total: ', data.total)
      setTripsList(data.items)
      if (data.total) {
        setTripsListTotalCount(data.total)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setTripsListIsLoading(false)
    }
  }

  const fetchEnableTrip = async (id, enabled) => {
    setTripsListIsLoading(true)
    try {
      let {data} = await enableTrip(id, enabled)
      let newTripsList = tripsList
      newTripsList.map((item) => {
        if (item.id === data.id) {
          item.status = data.status
        }
      })
      setTripsList(newTripsList)
    } catch (e) {
      console.log(e)
      try {
        let errors = e.response.data.data
        if (errors) {
          errors.errors.map((error) => {
            returnMessage('error', '', `${error.message}`, '', '', 5)
          })
        }
      } catch (e) {
        returnMessage('error', '', e.response.data.message, '', '', 5)
      }
    } finally {
      setTripsListIsLoading(false)
    }
  }

  const changeTripsListPage = (page, pageSize) => {
    setTripsListOffset(page * pageSize - pageSize)
    fetchTripsList(pageSize, page * pageSize - pageSize, tripsListOrderBy, tripsListOrderByDirection)
    changeParams(page, pageSize, page * pageSize - pageSize, tripsListOrderBy, tripsListOrderByDirection)
  }

  const changetripsListLimit = (limit) => {
    let currentItem = tripsListPage * tripsListLimit
    setTripsListLimit(limit)
    let page = tripsListPage
    if (currentItem > tripsListTotalCount) {
      page = Math.ceil(tripsListTotalCount/limit)
    }
    else {
      page = Math.floor(tripsListOffset/limit + 1)
    }
    setTripsListPage(page)
    setTripsListOffset(page * limit - limit)
    changeParams(page, limit, page * limit - limit, tripsListOrderBy, tripsListOrderByDirection)
    if (tripsList.length > 0) {
      fetchTripsList(limit, page * limit - limit, tripsListOrderBy, tripsListOrderByDirection)
    }
  }

  const changetripsListOrderBy = (value) => {
    setTripsListOrderBy(value)
    changeParams(tripsListPage, tripsListLimit, tripsListOffset, value, tripsListOrderByDirection)
    if (tripsList.length > 0) {
      fetchTripsList(tripsListLimit, tripsListOffset, value, tripsListOrderByDirection)
    }
  }

  const changetripsListOrderByDirection = (value) => {
    setTripsListOrderByDirection(value)
    changeParams(tripsListPage, tripsListLimit, tripsListOffset, tripsListOrderBy, value)
    if (tripsList.length > 0) {
      fetchTripsList(tripsListLimit, tripsListOffset, tripsListOrderBy, value)
    }
  }

  const changeParams = (page, limit, offset, orderBy, orderByDirection) => {
    navigate({
      pathname: TRIPS_ROUTE,
      search: `?${createSearchParams({
        page: page,
        limit: limit,
        offset: offset,
        orderBy: orderBy,
        orderByDirection: orderByDirection,
      })}`
    })
  }

  useEffect(() => {
    fetchTripsList()
    if (!params.get('page')) {
      changeParams(1, 50, 0, 'dateTime', 'desc')
    }
  }, [getStorageWithExpiry('accessToken')])

  return (
    <>
    {contextHolder}
    {modalEnableTripIsActive &&
      <Modal setModalIsActive={setModalEnableTripIsActive}>
        <div className={`${classes['enableTripModal-text']}`}>
          Вы уверены что хотите {(enableTripData.status === 'disabled' || enableTripData.status === 'boarding') ? 'включить' : 'отключить'} рейс {enableTripData.tripTemplate.name} от {dayjs(enableTripData.dateTime).format('DD.MM.YYYY HH:mm:ss')}
        </div>
        <div className={`${classes['enableTripModal-controls']}`}>
          <Button
            onClick={() => {
              fetchEnableTrip(enableTripData.id, (enableTripData.status === 'disabled' || enableTripData.status === 'boarding'))
              setModalEnableTripIsActive(false)
            }}
          >
            {(enableTripData.status === 'disabled' || enableTripData.status === 'boarding') ? 'Включить' : 'Отключить'}
          </Button>
          <Button
            onClick={() => setModalEnableTripIsActive(false)}
          >
            Отмена
          </Button>
        </div>
      </Modal>
    }
    <NavBar/>
    <Wrapper>
      <H1>Рейсы</H1>
      <div className={classes['tripsList']}>
        <div className={classes['tripsList-search']}>
          {!(tripsListTotalCount < tripsListLimit) &&
            <Pagination
              hideOnSinglePage
              rootClassName={'pagination'}
              disabled={tripsListTotalCount < tripsListLimit || tripsListIsLoading}
              current={tripsListPage}
              defaultCurrent={tripsListPage}
              defaultPageSize={tripsListLimit}
              pageSize={tripsListLimit}
              total={tripsListTotalCount}
              showSizeChanger={false}
              onChange={(page, pageSize) => {
                setTripsListPage(page)
                changeTripsListPage(page, pageSize)
              }}
            />
          }
           <div className={classes['tripsList-search-column']}>
            <Select
              loading={tripsListIsLoading}
              disabled={tripsListIsLoading}
              className={`select ${classes['tripsList-limit-select']}`}
              value={tripsListOrderBy}
              style={{width: '300px'}}
              placeholder='Сортировать по полю'
              label="true"
              optionFilterProp='label'
              onChange={(value) => changetripsListOrderBy(value)}
            >
              <Select.Option value='dateTime'>Дата</Select.Option>
              <Select.Option value='createdAt'>Дата создания</Select.Option>
            </Select>
            <Select
              loading={tripsListIsLoading}
              disabled={tripsListIsLoading}
              className={`select ${classes['tripsList-limit-select']}`}
              value={tripsListOrderByDirection}
              style={{width: '300px'}}
              placeholder='Сортировать по направлению'
              label="true"
              optionFilterProp='label'
              onChange={(value) => changetripsListOrderByDirection(value)}
            >
              <Select.Option value='asc'>По возрастанию</Select.Option>
              <Select.Option value='desc'>По убыванию</Select.Option>
            </Select>
            <Select
              loading={tripsListIsLoading}
              disabled={tripsListIsLoading}
              className={`select ${classes['tripsList-limit-select']}`}
              value={tripsListLimit}
              style={{width: '100px'}}
              placeholder='Количество записей'
              label="true"
              optionFilterProp='label'
              onChange={function(value) {
                changetripsListLimit(value)
              }}
            >
              <Select.Option value={10} key={10} label='10'>10</Select.Option>
              <Select.Option value={25} key={25} label='25'>25</Select.Option>
              <Select.Option value={50} key={50} label='50'>50</Select.Option>
              <Select.Option value={100} key={100} label='100'>100</Select.Option>
            </Select>
           </div>
        </div>
        <div className={classes['tripsList-list-wrapper']}>
          {tripsListIsLoading
          ?
            <LoadingSpin type='fullpage'/>
          :
            <div className={classes['tripsList-list']}>
              <div className={classes['tripsList-list-header']}>
                <div className={`${classes['tripsList-list-header-item']}`}>
                  <div className={`${classes['tripsList-list-header-item-title']}`}>Дата</div>
                </div>
                <div className={`${classes['tripsList-list-header-item']}`}>
                  <div className={`${classes['tripsList-list-header-item-title']}`}>Название</div>
                </div>
                <div className={`${classes['tripsList-list-header-item']}`}>
                  <div className={`${classes['tripsList-list-header-item-title']}`}>Статус</div>
                </div>
                <div className={`${classes['tripsList-list-header-item']}`}>
                  <div className={`${classes['tripsList-list-header-item-title']}`}>Создан</div>
                </div>
                <div className={`${classes['tripsList-list-header-item']}`}>
                  <div className={`${classes['tripsList-list-header-item-title']}`}>Действия</div>
                </div>
              </div>
              {tripsList.map((trip) => 
                <div className={classes['tripsList-list-trip']} key={trip.id} >
                  <div className={classes['tripsList-list-trip-data']}>
                    <div className={`${classes['tripsList-list-trip-data-item']}`}>
                      <div className={`${classes['tripsList-list-trip-data-item-value']}`}>{dayjs(trip.dateTime).format('DD.MM.YYYY HH:mm:ss')}</div>
                    </div>
                    <div className={`${classes['tripsList-list-trip-data-item']}`}>
                      <div className={`${classes['tripsList-list-trip-data-item-value']}`}>{trip.tripTemplate.name}</div>
                    </div>
                    <div className={`${classes['tripsList-list-trip-data-item']} ${classes['tripsList-list-trip-data-item__status']}`}>
                      <div className={`${classes['tripsList-list-trip-data-item-value']}`}>{tripStatuses ? tripStatuses[`${trip.status}`] : '-'}</div>
                    </div>
                    <div className={`${classes['tripsList-list-trip-data-item']}`}>
                      <div className={`${classes['tripsList-list-trip-data-item-value']}`}>{dayjs(trip.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                    </div>
                    <div className={`${classes['tripsList-list-trip-data-item']}`}>
                      <div className={`${classes['tripsList-list-trip-data-item-value']} ${classes['tripsList-list-trip-data-item-value__actions']}`}>
                        <NavLink to={`${TRIP_ROUTE}?id=${trip.id}`}>
                          <Button
                            className={`${classes['tripsList-list-trip-data-editButton']}`}
                            onClick={() => {dispatch(setTrip(trip))}}
                          >
                            Подробнее
                          </Button>
                        </NavLink>
                        <Button
                          className={`${classes['tripsList-list-trip-data-editButton']}`}
                          onClick={() => {
                            setEnableTripData(trip)
                            setModalEnableTripIsActive(true)
                          }}
                        >
                          {(trip.status === 'disabled' || trip.status === 'boarding') ? 'Включить' : 'Отключить'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
          {!(tripsListTotalCount < tripsListLimit) &&
            <Pagination
              hideOnSinglePage
              rootClassName={'pagination'}
              disabled={tripsListTotalCount < tripsListLimit}
              current={tripsListPage}
              defaultCurrent={tripsListPage}
              defaultPageSize={tripsListLimit}
              pageSize={tripsListLimit}
              total={tripsListTotalCount}
              showSizeChanger={false}
              onChange={(page, pageSize) => {
                setTripsListPage(page)
                changeTripsListPage(page, pageSize)
              }}
            />
          }
        </div>
      </div>
    </Wrapper>
  </>
  )
}

export default Trips