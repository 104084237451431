import React, { useCallback, useState } from 'react'
import classes from './Auth.module.css'
import Wrapper from '../../components/Wrapper/Wrapper'
import Icons from '../../components/Icons/Icons'
import { Input, notification } from 'antd'
import { useForm } from 'react-hook-form'
import { Button } from '../../components/Button/Button'
import { login,refresh_token } from '../../http/user'
import { useNavigate } from 'react-router-dom'
import { blue1, TRIPS_ROUTE } from '../../utils/consts'
import { setIsAuth } from '../../store/UserStore'
import { useDispatch } from 'react-redux'
import { setStorageWithExpiry } from '../../utils/setStorageWithExpiry'
import { getStorageWithExpiry } from '../../utils/getStorageWithExpiry'

const Auth = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const accessToken = getStorageWithExpiry('accessToken')
  const refreshToken = getStorageWithExpiry('refreshToken')

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setFocus,
    reset,
    formState: { errors, defaultValues },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    }
  })

  const [messageApi, contextHolder] = notification.useNotification()
  const returnMessage = (type, title, content, className, style, duration) => {
    messageApi.open({
      // type: type,
      message: title,
      description: content,
      className: className,
      style: style,
      duration: duration,
      placement: 'top',
      closeIcon:
        <Icons
          name='x'
          size='24px'
          stroke={blue1}
        />,
    })
  }
  console.log(getStorageWithExpiry('accessToken'))
  const onSubmit = async (formData) => {
    if (!accessToken) {
      if (!refreshToken) {
        try {
          let {data} = await login(formData.email, formData.password)
          setStorageWithExpiry('accessToken', data.accessToken.token, data.accessToken.expiresAt)
          setStorageWithExpiry('refreshToken', data.refreshToken.token, data.refreshToken.expiresAt)
          dispatch(setIsAuth(true))
          navigate({
            pathname: TRIPS_ROUTE,
          })
        } catch (e) {
          console.log(e)
          let errors = e.response.data.data
          if (errors) {
            errors.errors.map((error) => {
              returnMessage('error', '', `${error.propertyPath == null ? '' : `${error.propertyPath} - `}${error.message}`, 'notification', '', 10)
            })
          }
          else {
            returnMessage('error', '', e.response.data.message, 'notification', '', 10)
          }
        }
      }
      else {
        try {
          let {data} = await refresh_token(refreshToken)
          setStorageWithExpiry('accessToken', data.accessToken, data.expiresAt)
          dispatch(setIsAuth(true))
          navigate({
            pathname: TRIPS_ROUTE,
          })
        } catch (e) {
          console.log(e)
          let errors = e.response.data.data
          if (errors) {
            errors.errors.map((error) => {
              returnMessage('error', '', `${error.propertyPath == null ? '' : `${error.propertyPath} - `}${error.message}`, 'notification', '', 10)
            })
          }
          else {
            returnMessage('error', '', e.response.data.message, 'notification', '', 10)
          }
        }
      }
    }
    else {
      try {
        let {data} = await login(formData.email, formData.password)
        setStorageWithExpiry('accessToken', data.accessToken.token, data.accessToken.expiresAt)
        setStorageWithExpiry('refreshToken', data.refreshToken.token, data.refreshToken.expiresAt)
        dispatch(setIsAuth(true))
        navigate({
          pathname: TRIPS_ROUTE,
        })
      } catch (e) {
        console.log(e)
        let errors = e.response.data.data
        if (errors) {
          errors.errors.map((error) => {
            returnMessage('error', '', `${error.propertyPath == null ? '' : `${error.propertyPath} - `}${error.message}`, 'notification', '', 10)
          })
        }
        else {
          returnMessage('error', '', e.response.data.message, 'notification', '', 10)
        }
      }
    }
  }

  const getChangeHandlerWithValue = useCallback(name => value => {
    // console.log(name, value.target.value)
    if (value == '' || value == null) {
      setValue(name, null)
    }
    else if (name == 'firstName' || name == 'lastName' || name == 'middleName') {
      setValue(name, value.target.value.toString().toUpperCase())
    }
    else if (name == "phoneNumber") {
      setValue(name, value.target.value.replace(/[^+0-9]/gu, ''))
    }
    else if (value.target) {
      setValue(name, value.target.value)
    }
    else {
      setValue(name, value)
    }
  }, [])

  return (
    <Wrapper>
      {contextHolder}
      <div className={classes['wrapper-container']}>
        <div className={classes['auth-container']}>
          <div className={classes['auth']}>
            <form
              id='auth-form'
              className={classes['auth-form']}
              onSubmit={handleSubmit(onSubmit)}
              onChange={(e) => e.preventDefault()}
            >
              <Input
                className={'input'}
                placeholder='e-mail'
                {...register("email", {
                  required: "Email - обязательное поле",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/,
                    message: "Введите корректный email",
                  }
                })}
                onChange={getChangeHandlerWithValue("email")}
              />
              <Input
                className={'input'}
                placeholder='Пароль'
                type='password'
                {...register("password", {
                  validate: {
                    pattern: (value) => {return value.length >= 6 || 'Длина пароля не может быть меньше 6 символов'},
                    required: () => {
                      'Пароль - обязательное поле';
                    },
                  },
                })}
                onChange={getChangeHandlerWithValue("password")}
              />
              <div>
                <Button
                  onClick={() => handleSubmit(onSubmit)}
                >
                  Авторизоваться
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Auth