import React, { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { authRoutes, publicRoutes } from '../routes'
import { AUTH_ROUTE, NOTFOUND_ROUTE, TRIPS_ROUTE } from '../utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo, refresh_token } from '../http/user';
import { setIsAuth, setUserInfo } from '../store/UserStore';
import { getAllDictionaries } from '../http/dictionary';
import { setDictionaries } from '../store/DictionaryStore';
import { getStorageWithExpiry } from '../utils/getStorageWithExpiry';
import { setStorageWithExpiry } from '../utils/setStorageWithExpiry';

const AppRouter = () => {
  const dictionaries = useSelector(state => state.dictionary._dictionaries)
  const accessToken = getStorageWithExpiry('accessToken')
  const refreshToken = getStorageWithExpiry('refreshToken')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fetchUserInfo = async () => {
    if (getStorageWithExpiry('accessToken')) {
      try {
        let {data} = await getUserInfo()
        dispatch(setUserInfo(data))
      } catch (e) {
        console.log(e)
        if (e.response.status === 401) {
          navigate({pathname: AUTH_ROUTE})
        }
        if (e.response.status === 403) {
          navigate({pathname: AUTH_ROUTE})
        }
      }
    }
    else if (getStorageWithExpiry('refreshToken')) {
      fetchRefreshToken()
    }
    else {
      navigate({pathname: AUTH_ROUTE})
    }
  }

  const fetchAllDictionaries = async () => {
    try {
      let {data} = await getAllDictionaries()
      dispatch(setDictionaries(data))
    } catch (e) {
      console.log(e)
    }
  }

  const fetchRefreshToken = async () => {
    try {
      let {data} = await refresh_token(refreshToken)
      setStorageWithExpiry('accessToken', data.accessToken, data.expiresAt)
      dispatch(setIsAuth(true))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (getStorageWithExpiry('accessToken')) {
      dispatch(setIsAuth(true))
    }
    else {
      if (getStorageWithExpiry('refreshToken')) {
        fetchRefreshToken()
      }
    }
    if (getStorageWithExpiry('accessToken')) {
      fetchUserInfo()
    }
  }, [getStorageWithExpiry('accessToken'), getStorageWithExpiry('refreshToken')])

  useEffect(() => {
    if (!Object.keys(dictionaries).length) {
      fetchAllDictionaries()
    }
  }, [])
  
  return (
    <Routes>
      <Route path='/' element={<Navigate to={TRIPS_ROUTE}/>}/>
      {accessToken
      ?
        <Route path='*' element={<Navigate to={NOTFOUND_ROUTE}/>}/>
      :
        <Route path='*' element={<Navigate to={AUTH_ROUTE}/>}/>
      }
      {(getStorageWithExpiry('accessToken') || getStorageWithExpiry('refreshToken')) && authRoutes.map(({path, Component}) => 
        <Route key={path} path={path} element={
          <Suspense>
            <Component/>
          </Suspense>
        }/>
      )}
      {publicRoutes.map(({path, Component}) => 
        <Route key={path} path={path} element={
          <Suspense>
            <Component/>
          </Suspense>
        }/>
      )}
    </Routes>
  )
}

export default AppRouter